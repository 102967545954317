import { CartLine } from '@msdyn365-commerce/retail-proxy';
import { Button } from '@msdyn365-commerce-modules/utilities';
import classnames from 'classnames';
import * as React from 'react';
import { assembleUrlParams } from '../experlogix-d365commerce-core/urlUtilities';

const cartLineExtensionProperty_ExperlogixConfigurationId = 'ExperlogixConfigurationId';

export interface IExperlogixModifyCartLineButtonProps {
    cartLine: CartLine;
    labelText: string;
    productUrl: string | undefined;
}

export default class ExperlogixModifyCartLineButton extends React.PureComponent<IExperlogixModifyCartLineButtonProps> {
    public render(): JSX.Element | null {
        const { cartLine, labelText, productUrl } = this.props;
        // If there is no product, there's nothing configurable about this line
        if (!productUrl) {
            return null;
        }

        // If there is no product line id, this is weird, probably impossible, and may herald the end of all things.
        if (!cartLine.LineId) {
            return null;
        }

        // Check to see that this CartLine is configured
        if (!cartLine.ExtensionProperties?.find(p => p.Key === cartLineExtensionProperty_ExperlogixConfigurationId)) {
            return null;
        }

        return (
            <Button
                className={classnames('msc-cart-line__add-to-order-template', 'exp-cart-line__modify')}
                title={labelText}
                href={`${productUrl}?${assembleUrlParams({ key: 'cartLineId', value: cartLine.LineId })}`}
            >
                {labelText}
            </Button>
        );
    }
}
